import { Warning } from '@mui/icons-material';
import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoLocation = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
        textAlign: 'center',
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Warning color="error" sx={{ fontSize: 80 }} />
      </Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Sin ubicación
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
        Por favor, Ingresa una ubicación para encontrar tiendas cercanas.
      </Typography>
    </Container>
  );
};

export default NoLocation;
