const routesConstants = {
  initial: '/',
  home: '/home',
  orders: '/orders',
  branch: (branchId: string | undefined) => `/branch/${branchId}`,
  branchDef: `/branch/:branchId`,
  login: '/auth/login',
  signUp: '/auth/register',
  profile: '/profile',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  faq: '/faq',
  customerService: '/customer-service',
  termsAndConditions: '/terms-and-conditions',
  shopSignUp: '/shop-sign-up',
  deleteAccount: '/delete-account',
  branchByProductType: (type: string) => `/branches/product-type/${type}`,
  branchByProductTypeDef: 'branches/product-type/:type',
  branchByProductCategory: 'branches/product-category/:productCategoryId',
};

export default routesConstants;
